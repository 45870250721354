import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <div className="container mx-auto prose">
      <h1><strong parentName="h1">{`Advertisement :)`}</strong></h1>
      <ul>
        <li parentName="ul"><strong parentName="li"><a parentName="strong" {...{
              "href": "https://nodeca.github.io/pica/demo/"
            }}>{`pica`}</a></strong>{` - high quality and fast image
resize in browser.`}</li>
        <li parentName="ul"><strong parentName="li"><a parentName="strong" {...{
              "href": "https://github.com/nodeca/babelfish/"
            }}>{`babelfish`}</a></strong>{` - developer friendly
i18n with plurals support and easy syntax.`}</li>
      </ul>
      <p>{`You will like those projects!`}</p>
      <hr></hr>
      <h1>{`h1 Heading 8-)`}</h1>
      <h2>{`h2 Heading`}</h2>
      <h3>{`h3 Heading`}</h3>
      <h4>{`h4 Heading`}</h4>
      <h5>{`h5 Heading`}</h5>
      <h6>{`h6 Heading`}</h6>
      <h2>{`Horizontal Rules`}</h2>
      <hr></hr>
      <hr></hr>
      <h2>{`Typographic replacements`}</h2>
      <p>{`Enable typographer option to see result.`}</p>
      <p>{`(c) (C) (r) (R) (tm) (TM) (p) (P) +-`}</p>
      <p>{`test.. test... test..... test?..... test!....`}</p>
      <p>{`!!!!!! ???? ,, -- ---`}</p>
      <p>{`"Smartypants, double quotes" and 'single quotes'`}</p>
      <h2>{`Emphasis`}</h2>
      <p><strong parentName="p">{`This is bold text`}</strong></p>
      <p><strong parentName="p">{`This is bold text`}</strong></p>
      <p><em parentName="p">{`This is italic text`}</em></p>
      <p><em parentName="p">{`This is italic text`}</em></p>
      <p><del parentName="p">{`Strikethrough`}</del></p>
      <h2>{`Blockquotes`}</h2>
      <blockquote>
        <p parentName="blockquote">{`Blockquotes can also be nested...`}</p>
        <blockquote parentName="blockquote">
          <p parentName="blockquote">{`...by using additional greater-than signs right next to each other...`}</p>
          <blockquote parentName="blockquote">
            <p parentName="blockquote">{`...or with spaces between arrows.`}</p>
          </blockquote>
        </blockquote>
      </blockquote>
      <h2>{`Lists`}</h2>
      <p>{`Unordered`}</p>
      <ul>
        <li parentName="ul">{`Create a list by starting a line with `}<inlineCode parentName="li">{`+`}</inlineCode>{`, `}<inlineCode parentName="li">{`-`}</inlineCode>{`, or `}<inlineCode parentName="li">{`*`}</inlineCode></li>
        <li parentName="ul">{`Sub-lists are made by indenting 2 spaces:`}
          <ul parentName="li">
            <li parentName="ul">{`Marker character change forces new list start:`}
              <ul parentName="li">
                <li parentName="ul">{`Ac tristique libero volutpat at`}</li>
              </ul>
              <ul parentName="li">
                <li parentName="ul">{`Facilisis in pretium nisl aliquet`}</li>
              </ul>
              <ul parentName="li">
                <li parentName="ul">{`Nulla volutpat aliquam velit`}</li>
              </ul>
            </li>
          </ul>
        </li>
        <li parentName="ul">{`Very easy!`}</li>
      </ul>
      <p>{`Ordered`}</p>
      <ol>
        <li parentName="ol">
          <p parentName="li">{`Lorem ipsum dolor sit amet`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Consectetur adipiscing elit`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Integer molestie lorem at massa`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`You can use sequential numbers...`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`...or keep all the numbers as `}<inlineCode parentName="p">{`1.`}</inlineCode></p>
        </li>
      </ol>
      <p>{`Start numbering with offset:`}</p>
      <ol {...{
        "start": 57
      }}>
        <li parentName="ol">{`foo`}</li>
        <li parentName="ol">{`bar`}</li>
      </ol>
      <h2>{`Code`}</h2>
      <p>{`Inline `}<inlineCode parentName="p">{`code`}</inlineCode></p>
      <p>{`Indented code`}</p>
      <pre><code parentName="pre" {...{}}>{`// Some comments
line 1 of code
line 2 of code
line 3 of code
`}</code></pre>
      <p>{`Block code "fences"`}</p>
      <pre><code parentName="pre" {...{}}>{`Sample text here...
`}</code></pre>
      <p>{`Syntax highlighting`}</p>
      <pre><code parentName="pre" {...{
          "className": "language-js"
        }}>{`var foo = function (bar) {
  return bar++;
};

console.log(foo(5));
`}</code></pre>
      <h2>{`Tables`}</h2>
      <table>
        <thead parentName="table">
          <tr parentName="thead">
            <th parentName="tr" {...{
              "align": null
            }}>{`Option`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`Description`}</th>
          </tr>
        </thead>
        <tbody parentName="table">
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`data`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`path to data files to supply the data that will be passed into templates.`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`engine`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`engine to be used for processing templates. Handlebars is the default.`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`ext`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`extension to be used for dest files.`}</td>
          </tr>
        </tbody>
      </table>
      <p>{`Right aligned columns`}</p>
      <table>
        <thead parentName="table">
          <tr parentName="thead">
            <th parentName="tr" {...{
              "align": "right"
            }}>{`Option`}</th>
            <th parentName="tr" {...{
              "align": "right"
            }}>{`Description`}</th>
          </tr>
        </thead>
        <tbody parentName="table">
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": "right"
            }}>{`data`}</td>
            <td parentName="tr" {...{
              "align": "right"
            }}>{`path to data files to supply the data that will be passed into templates.`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": "right"
            }}>{`engine`}</td>
            <td parentName="tr" {...{
              "align": "right"
            }}>{`engine to be used for processing templates. Handlebars is the default.`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": "right"
            }}>{`ext`}</td>
            <td parentName="tr" {...{
              "align": "right"
            }}>{`extension to be used for dest files.`}</td>
          </tr>
        </tbody>
      </table>
      <h2>{`Links`}</h2>
      <p><a parentName="p" {...{
          "href": "http://dev.nodeca.com"
        }}>{`link text`}</a></p>
      <p><a parentName="p" {...{
          "href": "http://nodeca.github.io/pica/demo/",
          "title": "title text!"
        }}>{`link with title`}</a></p>
      <p>{`Autoconverted link `}<a parentName="p" {...{
          "href": "https://github.com/nodeca/pica"
        }}>{`https://github.com/nodeca/pica`}</a>{` (enable linkify to see)`}</p>
      <h2>{`Images`}</h2>
      <p><img parentName="p" {...{
          "src": "https://octodex.github.com/images/minion.png",
          "alt": "Minion"
        }}></img>{`
`}<img parentName="p" {...{
          "src": "https://octodex.github.com/images/stormtroopocat.jpg",
          "alt": "Stormtroopocat",
          "title": "The Stormtroopocat"
        }}></img></p>
      <p>{`Like links, Images also have a footnote style syntax`}</p>
      <p><img parentName="p" {...{
          "src": "https://octodex.github.com/images/dojocat.jpg",
          "alt": "Alt text",
          "title": "The Dojocat"
        }}></img></p>
      <p>{`With a reference later in the document defining the URL location:`}</p>
      <h2>{`Plugins`}</h2>
      <p>{`The killer feature of `}<inlineCode parentName="p">{`markdown-it`}</inlineCode>{` is very effective support of
`}<a parentName="p" {...{
          "href": "https://www.npmjs.org/browse/keyword/markdown-it-plugin"
        }}>{`syntax plugins`}</a>{`.`}</p>
      <h3>{`Hello?`}</h3>
      <p><a parentName="p" {...{
          "href": "https://github.com/markdown-it/markdown-it-emoji"
        }}>{`Emojies`}</a></p>
      <blockquote>
        <p parentName="blockquote">{`Classic markup: :wink: :crush: :cry: :tear: :laughing: :yum:`}</p>
        <p parentName="blockquote">{`Shortcuts (emoticons): :-) :-( 8-) ;)`}</p>
      </blockquote>
      <p>{`see `}<a parentName="p" {...{
          "href": "https://github.com/markdown-it/markdown-it-emoji#change-output"
        }}>{`how to change output`}</a>{` with twemoji.`}</p>
      <h3><a parentName="h3" {...{
          "href": "https://github.com/markdown-it/markdown-it-sub"
        }}>{`Subscript`}</a>{` / `}<a parentName="h3" {...{
          "href": "https://github.com/markdown-it/markdown-it-sup"
        }}>{`Superscript`}</a></h3>
      <ul>
        <li parentName="ul">{`19^th^`}</li>
        <li parentName="ul">{`H~2~O`}</li>
      </ul>
      <h3>{`Test`}</h3>
      <p><a parentName="p" {...{
          "href": "https://github.com/markdown-it/markdown-it-ins"
        }}>{`ins`}</a></p>
      <p>{`++Inserted text++`}</p>
      <h3><a parentName="h3" {...{
          "href": "https://github.com/markdown-it/markdown-it-mark"
        }}>{`mark`}</a></h3>
      <p>{`==Marked text==`}</p>
      <h3><a parentName="h3" {...{
          "href": "https://github.com/markdown-it/markdown-it-footnote"
        }}>{`Footnotes`}</a></h3>
      <p>{`Footnote 1 link`}<sup parentName="p" {...{
          "id": "fnref-first"
        }}><a parentName="sup" {...{
            "href": "#fn-first",
            "className": "footnote-ref"
          }}>{`first`}</a></sup>{`.`}</p>
      <p>{`Footnote 2 link`}<sup parentName="p" {...{
          "id": "fnref-second"
        }}><a parentName="sup" {...{
            "href": "#fn-second",
            "className": "footnote-ref"
          }}>{`second`}</a></sup>{`.`}</p>
      <p>{`Inline footnote^`}{`[Text of inline footnote]`}{` definition.`}</p>
      <p>{`Duplicated footnote reference`}<sup parentName="p" {...{
          "id": "fnref-second"
        }}><a parentName="sup" {...{
            "href": "#fn-second",
            "className": "footnote-ref"
          }}>{`second`}</a></sup>{`.`}</p>
      <h3><a parentName="h3" {...{
          "href": "https://github.com/markdown-it/markdown-it-deflist"
        }}>{`Definition lists`}</a></h3>
      <p>{`Term 1`}</p>
      <p>{`: Definition 1
with lazy continuation.`}</p>
      <p>{`Term 2 with `}<em parentName="p">{`inline markup`}</em></p>
      <p>{`: Definition 2`}</p>
      <pre><code parentName="pre" {...{}}>{`    { some code, part of Definition 2 }

Third paragraph of definition 2.
`}</code></pre>
      <p><em parentName="p">{`Compact style:`}</em></p>
      <p>{`Term 1
~ Definition 1`}</p>
      <p>{`Term 2
~ Definition 2a
~ Definition 2b`}</p>
      <h3><a parentName="h3" {...{
          "href": "https://github.com/markdown-it/markdown-it-abbr"
        }}>{`Abbreviations`}</a></h3>
      <p>{`This is HTML abbreviation example.`}</p>
      <p>{`It converts "HTML", but keep intact partial entries like "xxxHTMLyyy" and so on.`}</p>
      <p>{`*`}{`[HTML]`}{`: Hyper Text Markup Language`}</p>
      <h3><a parentName="h3" {...{
          "href": "https://github.com/markdown-it/markdown-it-container"
        }}>{`Custom containers`}</a></h3>
    </div>

    <div {...{
      "className": "footnotes"
    }}>
      <hr parentName="div"></hr>
      <ol parentName="div">
        <li parentName="ol" {...{
          "id": "fn-first"
        }}>
          <p parentName="li">{`Footnote `}<strong parentName="p">{`can have markup`}</strong></p>
          <p parentName="li">{`and multiple paragraphs.`}<a parentName="p" {...{
              "href": "#fnref-first",
              "className": "footnote-backref"
            }}>{`↩`}</a></p>
        </li>
        <li parentName="ol" {...{
          "id": "fn-second"
        }}>
          <p parentName="li">{`Footnote text.`}<a parentName="p" {...{
              "href": "#fnref-second",
              "className": "footnote-backref"
            }}>{`↩`}</a></p>
        </li>
      </ol>
    </div>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      